import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import TermsConditions from "../../../blocks/termsconditions/src/TermsConditions.web";
import { barcodeIp, checkedIcon, hideEye, image_bg, redCircle, showEye, uncheckedIcon } from "./assets";

const LogInContainerCss = styled(Box)({
  "& .MuiContainer-maxWidthLg": {
    maxWidth: "1440px",
  },
});

const alerat = {
  container: {
    background: "#F5C6C0",
    borderLeft: "4px solid #DC2626",
    color: "#DC2626",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
  }
}

const LogInCss = styled(Box)({
  display: "flex",
  height: "100vh",
  overflow: "auto",
  "& .LoginHiddenContainer": {
    overflow: "hidden"
  },
  "& .LogInImageContainer": {
    width: "100%",
    height: "100vh",
  },
  "& .LogInImgMainBox": {
    position: "relative",
  },
  "& .LogInImgText": {
    padding: "100px",
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  "& .LogInImgTextBox": {
    position: "absolute",
    left: "0",
    top: "0",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  "& .LogInImgMainText": {
    color: "#FFF",
    display: "flex",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "64px",
  },
  "& .LogInSubText": {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .LogInPrimaryTextBox": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  "& .LogInSecondaryTextBox": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .LogInFormTextFieldBox": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .LogInFormLoginBox": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "@media (max-width: 330px)": {
      flexDirection: "column",
    },
  },
  "& .LogInAlreadyAcc": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#4F5356",
  },
  "& .LogInCreateAcc": {
    color: "#DF4833",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
  },
  "& .LogInCreateAcc-dif": {
    color: "#DF4833",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
  },
  "& .MuiTypography-root": {
    fontFamily: "Poppins",
  },
  "& .LogInWelcome": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    color: "#4F5356",
  },
  "& .LogInTypography": {
    fontSize: "30px",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    color: "#4F5356",
  },
  "& .LogInDetails": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#4F5356",
  },
  "& .lableContainer": {
    display: "flex",
  },
  "& .inputLable": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#4F5356",
  },
  "& .LogInButton": {
    background: "#DF4833",
    color: "#FFFFFF",
    fontWeight: 700,
    width: "100%",
    padding: 16,
    borderRadius: "10px",
    textTransform: "none",
    cursor: "pointer",
    "& .MuiButton-root": {
      fontFamily: "Poppins",
    },
  },
  "& .LogInFormContainer": {
    backgroundColor: "#E8E7E7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: 'scroll',
    height: "100%"
  },
  "& .FormTextField": {
    backgroundColor: "white",
    borderRadius: "10px",
    "&::placeholder": {
      color: '#AEB0B1',
      fontFamily: "Inter",
      fontWeight: 400,
      opacity: 1,
      fontSize: 16
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .FormTextField-placeholder": {
    "&::placeholder": {
      color: '#AEB0B1',
      fontFamily: "Inter",
      fontWeight: 400,
      opacity: 1,
      fontSize: 16
    },
  },
  "& .main-field": {
    position: "relative",
    "& .icon-eyes": {
      position: "absolute",
      top: 5,
      right: 5
    }
  },
  "& .logIn-Remember": {
    color: "#4F5356",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .logIn-Forgot": {
    color: "#3B82F6",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "end",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    height: '100%',
    cursor: "pointer"
  },
  "& .logIn-remember-check": {
    display: "flex",
    alignItems: "center",
    height: 25
  },
  "& .logIn-checkbox-label": {
    padding: 0,
    paddingRight: 8
  },
  "& .logIn-agree-checkbox": {
    display: 'flex',
    alignItems: 'center'
  },
});

const TermAndConditionDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    padding: "24px 40px",
    borderBottom: "1px solid #CBD5E1"
  },
  "& .MuiDialogContent-root": {
    padding: "40px",
  },
  "& .MuiDialogActions-root": {
    padding: "24px",
    borderTop: "1px solid #CBD5E1"
  },
  "& .success-modal": {
    background: '#F5F5F4',
    borderRadius: 8,
    maxWidth: 600,
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .closeIconStyle": {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 14
  },
  "& .acceptBtn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 150,
    borderRadius: "8px",
    fontSize: "16px",
    background: "#EF4444",
    gap: "8px",
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
  },
  "& .acceptBtn-disable": {
    color: "#FFF",
    height: 56,
    fontSize: "16px",
    width: 150,
    fontStyle: "normal",
    borderRadius: "8px",
    background: "#E87171",
    textTransform: "none",
    padding: "10px 16px",
    gap: "8px",
    fontWeight: 700,
    fontFamily: "Inter",
    cursor: "pointer",
    border: 'none',
  },
  "& .cancleBtn": {
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Inter",
    background: "#FCEDEB",
    fontWeight: 700,
    color: "#DF4833",
    textTransform: "none",
    height: 56,
    cursor: "pointer",
    width: 150,
    border: 'none',
    padding: "10px 16px",
    borderRadius: "8px",
    gap: "8px",
  },
  "& .FormTextField": {
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      top: -6
    },
    "& .MuiInputBase-root": {
      fontFamily: "Inter"
    },
    '& .Mui-focused fieldset': {
      borderColor: '#CBD5E1',
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .dailog-HeadingText": {
    '& .MuiTypography-h6': {
      color: "#4F5356",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 700,
      "@media (max-width: 524px)": {
        textAlign: "center"
      },
    },
  },
  "& .dailog-contentText1": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .logIn-remember-check": {
    height: 25,
    display: "flex",
    alignItems: "center",
  },
  "& .logIn-agree-checkbox": {
    display: 'flex',
    alignItems: 'center',
    height: 15,
    marginTop: 32
  },
  "& .logIn-Remember": {
    color: "#4F5356",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .LogInCreateAcc-dif": {
    color: "#DF4833",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
  },
  "& .logIn-checkbox-label": {
    padding: 0,
    paddingRight: 8
  },
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <LogInContainerCss>
        <LogInCss>
          <Grid container className="LoginHiddenContainer">
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <Box className="LogInImgMainBox">
                  <img
                    src={image_bg}
                    alt="bgImage"
                    className="LogInImageContainer"
                  />
                  <Box className="LogInImgTextBox">
                    <Box className="LogInImgText">
                      <Box>
                        <img src={redCircle} alt="redCircle" />
                      </Box>
                      <Box>
                        <Typography className="LogInImgMainText">
                          Grow with us
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="LogInSubText">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Morbi lobortis maximus nunc, ac rhoncus odio
                          congue quis. Sed ac semper orci, eu porttitor lacus.{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Hidden>
            <Grid container className="LogInFormContainer" item md={6} xs={12}>
              <Grid
                container
                spacing={3}
                item
                xs={11}
                sm={7}
                md={6}
                lg={7}
                xl={8}
              >
                <Grid item xs={12}>
                  <Box className="LogInPrimaryTextBox">
                    <Typography variant="subtitle1" className="LogInWelcome">
                      Welcome to
                    </Typography>
                    <img
                      src={barcodeIp}
                      alt="barcodeIp"
                      height={36}
                      width={250}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="LogInSecondaryTextBox">
                    <Typography
                      variant="h4"
                      component="h1"
                      className="LogInTypography"
                      data-test-id="heading"
                    >
                      Log in
                    </Typography>
                    <Typography variant="body1" className="LogInDetails">
                      Welcome back! Please enter your details.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={!!this.state.formErrors.terms}>
                    {<Alert style={alerat.container} severity="error" icon={false}>{this.state.formErrors.terms}</Alert>}
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box className="LogInFormTextFieldBox">
                    <Box className="lableContainer">
                      <Typography className="inputLable">Email</Typography>
                    </Box>
                    <TextField
                      className="FormTextField"
                      variant="outlined"
                      placeholder="Enter Email"
                      name="email"
                      type="email"
                      id="email"
                      required
                      fullWidth
                      value={this.state.formValues.email}
                      onChange={this.handleChangeEmail}
                      error={Boolean(this.state.formErrors.email)}
                      helperText={this.state.formErrors.email}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="LogInFormTextFieldBox">
                    <Box className="lableContainer">
                      <Typography className="inputLable">Password</Typography>
                    </Box>
                    <Box className="main-field">
                      <TextField
                        className="FormTextField"
                        placeholder="Enter password"
                        variant="outlined"
                        type={this.state.changeIcon ? "text" : "password"}
                        name="password"
                        id="password"
                        fullWidth
                        required
                        value={this.state.formValues.password}
                        onChange={this.handleChangePassword}
                        error={Boolean(this.state.formErrors.password)}
                        helperText={this.state.formErrors.password}
                      />
                      <IconButton className="icon-eyes" id="icon-eyes" onClick={() => this.onClickShowPasswordIcon()}>
                        {!this.state.changeIcon ? (
                          <img src={hideEye} alt="hideEye" />
                        ) : (
                          <img src={showEye} alt="showEye" />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="logIn-remember-check">
                    <Checkbox

                      checked={this.state.formValues.rememberMe}
                      // checked={this.state.rememberMe}
                      // onChange={() => this.handleChangeRemember(true)}

                      onChange={() => this.onChangeCheckBoxForRemember()}
                      data-test-id = "changeCheckBoxDataTestId"
                      

                      checkedIcon={
                        <img
                          src={checkedIcon}
                          alt="barcodeIp"
                        />
                      } icon={
                        <img
                          src={uncheckedIcon}
                          alt="barcodeIp"
                        />
                      } color="primary" className="logIn-checkbox-label" />

                    <Box className="logIn-Remember">Remember Me</Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="logIn-Forgot" id="resetPassword" onClick={this.handleResetPassword}>Forgot password</Box>
                </Grid>
                <TermAndConditionDialog
                  open={this.state.resetPassword}
                  onClose={this.handleCloseResetPassword}
                  id="close-reserPassword"
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    className: "success-modal",
                    elevation: 0
                  }}
                  BackdropProps={{
                    className: "backdrop-modal",
                  }}
                >
                  <DialogTitle className="dailog-HeadingText" id="reserPassword-title">{"Reset Password"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText className="dailog-contentText1" id="alert-dialog-description">
                      Please connect with Administrator <b>admin@barocodeip.com</b>
                    </DialogContentText>
                    <DialogContentText className="dailog-contentText1" id="alert-dialog-description">
                      to reset your password.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Grid item>
                      <button className="acceptBtn" id="click-close-reserPassword" onClick={() => this.handleCloseResetPassword()}>
                        Got it
                      </button>
                    </Grid>
                  </DialogActions>
                </TermAndConditionDialog>
                <Grid item xs={12} className="logIn-agree-checkbox">
                  <Checkbox
                    checked={this.state.termsAndCondition}
                    onChange={(event) => this.handleChangeTerms(event)}
                    id="check-box-Terms"
                    checkedIcon={
                      <img
                        src={checkedIcon}
                        alt="barcodeIp"
                      />
                    } icon={
                      <img
                        src={uncheckedIcon}
                        alt="barcodeIp"
                      />
                    } color="primary" className="logIn-checkbox-label" />
                  <Box className="logIn-Remember">I Agree with
                    <Box component="span" className="LogInCreateAcc-dif"
                      id="privacy-model-btn"
                      
                      onClick={this.hendleOpenTAndCModel.bind(this,"Privacy")}>
                        
                        Privacy Policy</Box>
                    and <Box component="span" className="LogInCreateAcc-dif"
                      id="terms-model-btn"
                      onClick={() => this.hendleOpenTAndCModel("Terms")}>Terms & Conditions</Box></Box>
                </Grid>
                <TermsConditions
                  navigation={undefined}
                  id={"TermsConditions"}
                  getTermDoc={this.getTermDoc}
                  openTermAndConditionModel={this.state.openTermAndConditionModel}
                  modelType={this.state.modelType}
                  formValTerms={this.state.termsAndCondition}
                  handleChangeTerms={this.handleChangeTerms}
                  hendleOpenTAndCModel={this.hendleOpenTAndCModel}
                  handleCloseTAndCModel={this.handleCloseTAndCModel}
                />
                <Grid item xs={12}>
                  <Button className="LogInButton" id="login-btn" variant="contained" onClick={() => this.handleLogIn()}>
                    Log In
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Box className="LogInFormLoginBox">
                    <Typography className="LogInAlreadyAcc">
                      New to BarcodeIP.?
                    </Typography>
                    <Typography className="LogInCreateAcc" id="createAcc" onClick={() => this.goToSignUpPage()}>Create Account</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LogInCss>
      </LogInContainerCss>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
