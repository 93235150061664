import React, { useState } from 'react';
import { Box, Button, TextField, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check'; 
import TitleIcon from '@mui/icons-material/Title';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Define a section's structure
export interface Section {
  title: string;
  subtitle: string;
  index: number;
  content: string;
  setContent: (content: string) => void;
}

// Custom toolbar configuration
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'link'], 
    [{ 'undo': 'undo' }, { 'redo': 'redo' }] 
  ],
};

// Sections array
const sectionsData: Section[] = [
  {
    title: "Title of the invention",
    subtitle: "What is the title for your invention?",
    index: 1,
    content: '',
    setContent: (content: string) => {},
  },
  {
    title: "Abstract of the invention",
    subtitle: "What is the Abstract for your invention?",
    index: 2,
    content: '',
    setContent: (content: string) => {},
  },
  {
    title: "Background of the invention",
    subtitle: "What is the Background for your invention?",
    index: 3,
    content: '',
    setContent: (content: string) => {},
  },
  {
    title: "Objective of the invention",
    subtitle: "What is the Objective for your invention?",
    index: 4,
    content: '',
    setContent: (content: string) => {},
  },
  {
    title: "Summary of the invention",
    subtitle: "What is the Summary for your invention?",
    index: 5,
    content: '',
    setContent: (content: string) => {},
  },
];

// Individual section component
const SectionComponent: React.FC<{ section: Section }> = ({ section }) => {
  const [content, setContent] = useState(section.content);

  // Handler for quill editor content
  const handleContentChange = (value: string) => {
    setContent(value);
    section.setContent(value); // Update the parent state
  };

  return (
    <Box sx={{
      position: 'sticky', backgroundColor: '#F5F5F4', fontFamily: "Inter",
      top: `${(section.index - 1) * 69}px`, border: '1px solid #ddd', padding: '24px', paddingTop: '12px', borderRadius: '10px', marginBottom: '32px'
    }}>
      
      <h3 style={{ fontWeight: 'bold', margin: '8px' }}>{section.title}</h3>

      <h5 style={{ fontWeight: 'normal', color: '#474747', margin: '8px' }}>{section.subtitle}</h5>

      <Box sx={{ position: 'relative', marginBottom: '16px' }}>
        <ReactQuill
          theme="snow"
          value={content}
          onChange={handleContentChange}
          modules={modules}
          style={{ backgroundColor: '#fff', height: '150px', borderRadius: '10px' }}
          data-testid={`quill-editor-${section.index}`} // Add unique test ID
        />

        <Button
          className='Save'
          data-testid={`save-button-${section.index}`} // Add unique test ID
          sx={{
            position: 'absolute',
            top: '5px',
            height: '32px',
            right: '10px',
            backgroundColor: '#f44336',
            color: '#fff',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#f44336',  
            },
          }}
          onClick={() => alert('Save Clicked')}
          startIcon={<CheckIcon />}
        >
          Save
        </Button>

        <Box sx={{ position: 'absolute', top: '5px', right: '90px', display: 'flex', gap: '5px' }}>
          <IconButton>
            <UndoIcon />
          </IconButton>
          <IconButton>
            <RedoIcon />
          </IconButton>
        </Box>
      </Box>

      <TextField
        variant="outlined"
        placeholder="Summary of the invention"
        fullWidth
        InputProps={{
          startAdornment: <SearchIcon sx={{ color: '#6c757d', marginRight: '8px' }} />,
          endAdornment: (
            <IconButton>
              <ArrowForwardIosIcon sx={{ color: '#f44336' }} />
            </IconButton>
          ),
        }}
        sx={{ backgroundColor: '#fff', borderRadius: '10px', marginBottom: '16px' }}
      />

      <Box sx={{ display: 'flex', gap: '8px', padding: '8px', borderRadius: '8px' }}>
        <Button sx={{ border: '1px solid #ddd', color: '#474747', textTransform: 'none', borderRadius: '8.5px', paddingLeft: '0px' }}>
          <TitleIcon /> Write with AI Type
        </Button>
        <Button sx={{ border: '1px solid #ddd', color: '#474747', textTransform: 'none', borderRadius: '8.5px', paddingLeft: '0px' }}>
          <TitleIcon /> Rewrite {section.title.split(' ')[0]}
        </Button>
      </Box>
    </Box>
  );
};

// Main parent component
const ProvisionalSpecification: React.FC = () => {
  const [sections, setSections] = useState<Section[]>(sectionsData.map(section => ({
    ...section,
    setContent: (content: string) => {
      setSections(prevSections =>
        prevSections.map(sec =>
          sec.title === section.title ? { ...sec, content } : sec
        )
      );
    },
  })));

  return (
    <Box sx={{
      padding: '24px',
      display: 'grid',
      gridTemplateColumns: '1fr',
    }}>
      {sections.map((section, index) => (
        <SectionComponent
          key={index} section={section}
        />
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
        <Button
          sx={{
            top: '25px',
            backgroundColor: '#f44336',
            color: '#fff',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#f44336', 
            },
          }}
          startIcon={<CalendarMonthIcon />}
        >
          View Specification
        </Button>
      </Box>
    </Box>
  );
};

export default ProvisionalSpecification;
