import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export interface Section {
  title: string;
  subtitle: string;
  index: number;
  content: string;
  prompt:string
  keytitle: string;
}
// Define the interface for the entire API response
export interface SpecificationContent {
  content: string;
}

export interface Specification {
  [key: string]: SpecificationContent;
}

export interface ApiResponse {
  Specification: Specification;
}
export interface InventionContent {
  title: string;
  content: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sections:Section[]
  loading:boolean
  abstract:string
  errors:boolean
  selection:string
  promptindexcall:number
  disableprompt:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProvisionalSpecificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [ 
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      errors:false,
      sections:[],
      loading:false,
      abstract:'',
      selection:'',
      promptindexcall:0,
      disableprompt:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const tokenExpire = responseJson.errors?.[0]?.token
    if (tokenExpire) {
      this.goingToLogInPage()
    }
    else if (apiRequestCallId === this.apiProvisonalDetailsAPIEndPoint) {
      if (responseJson.errors?.length) {
        this.setState({
          loading: false, errors: true
        }, () => {
          setStorageData("BackTrue", "BackTrue")
        })
      }
      else if (responseJson.Specification) {
        const sectionsData = this.mapApiResponseToSections(responseJson);
        this.setState({ sections: sectionsData, loading: false })
      }
      setStorageData("BackTrue", "BackTrue")
    }
    else if (apiRequestCallId === this.apipromptCallID) {
      if (responseJson.errors?.length) {
        this.setState({
          loading: false, errors: true,disableprompt: false
        }, () => {
        })
      }
      else {
        const response1Text = responseJson.Specification.join("\n\n");
        const indexcall = this.state.promptindexcall
        this.setState(prevState => ({
          sections: prevState.sections.map((section, index) => ({
            ...section,
            prompt: index === indexcall ? response1Text : section.prompt // Update content for a specific section
          }))
        }));
        this.setState({ disableprompt: false })
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiProvisonalDetailsAPIEndPoint:string=""
  apipromptCallID:string=""
  async componentDidMount() {
    const loginToken = await getStorageData("token")
    if(!loginToken){
      this.goingToLogInPage()
    }    
    const serachAgain = await getStorageData("advanchData")
    this.setState({abstract:serachAgain})
    this.getProvisionalDetailsAPI();
  };

  handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection) {
      this.setState({selection:selection.toString()})
    }
  };

  goingToLogInPage = async () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageLogin);
  };
  
  mapApiResponseToSections = (apiResponse: ApiResponse): Section[] => {
      const contentArray: InventionContent[] = Object.entries(apiResponse.Specification).map(([key, value]) => ({
        title: key,
        content: value.content,
      }));    
      return [
        {
          title: "Title of the invention",
          subtitle: "What is the title for your invention?",
          index: 0,
          content: contentArray[0].content,
          prompt:'',
          keytitle: contentArray[0].title
        },
        {
          title: "Abstract of the invention",
          subtitle: "What is the Abstract for your invention?",
          index: 1,
          content: contentArray[3].content,
          prompt:'',
          keytitle: contentArray[3].title
        },
        {
          title: "Background of the invention",
          subtitle: "What is the Background for your invention?",
          index: 2,
          content: contentArray[1].content, 
          prompt:'',
          keytitle: contentArray[1].title
        },
        {
          title: "Summary of the invention",
          subtitle: "What is the Summary for your invention?",
          index: 3,
          content: contentArray[2].content,
          prompt:'',
          keytitle: contentArray[2].title
        },
      ]; 
  };

  handlePromptChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, targetIndex: number) => {
    const { value } = event.target; 
    
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        prompt: index === targetIndex ? value : section.prompt // Update content for a specific section
      }))
    }));
    this.setState({promptindexcall:targetIndex});
  };


  promptApiCall=async (index:number)=>
  {
    this.setState({disableprompt:true})
    const selection=this.state.selection;
    const prompt=this.state.sections[index].prompt;
    const type=this .state.sections[index].keytitle 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apipromptCallID = requestMessage.messageId;
    
    const params = new URLSearchParams({
      type:type,
      abstract: selection,
      prompt: prompt,
    }).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apipromptEndPoint}?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProvisionalDetailsAPI = async () => {
    this.setState({ loading: true });
    const header = {
      token: await getStorageData("token"),
    };
    const abstract=this.state.abstract
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvisonalDetailsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/generate_provisional_specification?abstract=${abstract}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleContentChange = (value: string,targetIndex:number) => {
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        content: index === targetIndex ? value : section.content // Update content for a specific section
      }))
    }));
}

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End