import React from 'react';
// Customizable Area Start

import { Box, Button, TextField, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check'; 
import TitleIcon from '@mui/icons-material/Title';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ProvisionalSpecificationPageController,{Props} from "./ProvisionalSpecificationPageController.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End


// Customizable Area Start
// Define a section's structure
export interface Section {
  title: string;
  subtitle: string;
  index: number;
  content: string;
  prompt:string;
  keytitle: string;
}

// Custom toolbar configuration
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'link'], 
    [{ 'undo': 'undo' }, { 'redo': 'redo' }] 
  ],
};

// Customizable Area End
export default class ProvisionalSpecificationPage extends ProvisionalSpecificationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    return (
      <>
      {
        this.state.loading ? (
          <Loader loading={this.state.loading} />
        ) : (
          <>
            {!this.state.errors ? (
              <Box key={'Allsection'} sx={{
                padding: '24px',
                display: 'grid',
                gridTemplateColumns: '1fr',
              }}>
                {this.state.sections.map((section: Section, index: number) => (
                  <div key={`section-${section.index}`} className={`section-quill-${section.index}`} onMouseUp={this.handleMouseUp}> 
                    <Box sx={{
                      position: 'sticky',
                      backgroundColor: '#F5F5F4',
                      fontFamily: "Inter",
                      top: `${(section.index - 1) * 69}px`,
                      border: '1px solid #ddd',
                      padding: '24px',
                      paddingTop: '12px',
                      borderRadius: '10px',
                      marginBottom: '32px'
                    }}>
                      <h3  id={`section-title-${section.index}`} style={{ fontWeight: 'bold', margin: '8px' }}>{section.title}</h3>
                      <h5 id={`section-subtitle-${section.index}`} style={{ fontWeight: 'normal', color: '#474747', margin: '8px' }}>{section.subtitle}</h5>
                      <Box sx={{ position: 'relative', marginBottom: '16px' }}>
                        <ReactQuill
                          theme="snow"
                          value={section.content}
                          onChange={(e) => this.handleContentChange(e, index)}
                          modules={modules}
                          style={{ backgroundColor: '#fff', height: '150px', borderRadius: '10px' }}
                          data-testid={`quill-editor-${section.index}`} 
                          id={`quill-editor-${section.index}`}
                        />
                        <Button
                          className='Save'
                          data-testid={`save-button-${section.index}`} 
                          id={`save-button-${section.index}`} 
                          sx={{
                            position: 'absolute',
                            top: '5px',
                            height: '32px',
                            right: '10px',
                            backgroundColor: '#f44336',
                            color: '#fff',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#f44336',
                            },
                          }}
                          // onClick={() => alert('Save Clicked')}
                          startIcon={<CheckIcon />}
                        >
                          Save
                        </Button>
                        <Box sx={{ position: 'absolute', top: '5px', right: '90px', display: 'flex', gap: '5px' }}>
                          <IconButton id={`undo-button-${section.index}`} aria-label="undo">
                            <UndoIcon />
                          </IconButton>
                          <IconButton id={`redo-button-${section.index}`} aria-label="redo">
                            <RedoIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <TextField
                        className={`prompt-${section.index}`}
                        variant="outlined"
                        placeholder="Summary of the invention"
                        fullWidth
                        id={`prompt-${section.index}`}
                        data-testid={`prompt-${section.index}`}
                        value={section.prompt}
                        onChange={(e) => this.handlePromptChange(e, index)}
                        multiline
                        InputProps={{
                          startAdornment: <SearchIcon sx={{ color: '#6c757d', marginRight: '8px' }} />,
                          endAdornment: (
                            <IconButton  
                              id={`promptcall-${section.index}`}
                              className={`promptcall-${section.index}`}
                              data-testid={`promptcall-${section.index}`}
                              onClick={() => this.promptApiCall(index)}
                              disabled={this.state.disableprompt} 
                              >
                              <ArrowForwardIosIcon sx={{ color: '#f44336' }} />
                            </IconButton>
                          ),
                        }}
                        sx={{ backgroundColor: '#fff', borderRadius: '10px', marginBottom: '16px', overflow: 'hidden' }}
                      />

                      <Box sx={{ display: 'flex', gap: '8px', padding: '8px', borderRadius: '8px' }}>
                        <Button id={`writeAI-${section.index}`}  data-testid={`writeAI-${section.index}`}  sx={{ border: '1px solid #ddd', color: '#474747', textTransform: 'none', borderRadius: '8.5px', paddingLeft: '0px' }}>
                          <TitleIcon /> Write with AI Type
                        </Button>
                        <Button id={`rewrite-${section.index}`}  data-testid={`rewrite-${section.index}`}  sx={{ border: '1px solid #ddd', color: '#474747', textTransform: 'none', borderRadius: '8.5px', paddingLeft: '0px' }}>
                          <TitleIcon /> Rewrite {section.title.split(' ')[0]}
                        </Button>
                      </Box>
                    </Box>
                  </div>
                ))}
    
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                  <Button
                    id="view-specification-button" 
                    data-testid="view-specification-button"
                    sx={{
                      top: '25px',
                      backgroundColor: '#f44336',
                      color: '#fff',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#f44336',
                      },
                    }}
                    startIcon={<CalendarMonthIcon />}
                  >
                    View Specification
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box component="div" className="no-result-text">No Results Found</Box>
            )}
          </>
        )
      }
    </>
    
    
    );
  }
}

// Customizable Area Start

// Customizable Area End
