import React from "react";
import { CircularProgress } from "@mui/material";
import { Box, Dialog, DialogContent } from "@material-ui/core";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return props.loading ? (
    <Dialog
      open={props.loading}
      maxWidth={"xs"}
      fullWidth PaperProps={{
        elevation: 0,
        style: {
          background: "transparent"
        }
      }}>
      <DialogContent>
        <Box style={webStyles.root}>
        Results are getting ready
        </Box>
      </DialogContent>
    </Dialog>
  ) : (
    <div />
  );
}

const webStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0",
    background:'grey',
    padding:'8px',
    borderRadius:'9PX',
    border:'0.5px solid #EF4444'
  },
};